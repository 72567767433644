<template>
  <div class="reception-classic-cases-detail">
    <div class="w">
      <div v-show="imgs.length > 1" class="swiper">
        <div class="swiper-container">
          <div class="swiper-wrapper">
            <div class="swiper-slide" v-for="(item, index) in imgs" :key="index">
              <img :src="item" alt="">
            </div>
          </div>
        </div>
      </div>
      <div class="one-img" v-show="imgs.length === 1">
        <img :src="imgs[0]" alt="">
      </div>
      <div v-show="imgs.length <= 0" style="margin-bottom: 30px;"></div>
      <div class="content-box">
        <div class="title-box">
          <div class="title">{{ detailData.name }}</div>
          <div class="date">{{ detailData.lastPubTime }}</div>
        </div>
        <div class="content" v-html="detailData.briefText">
        </div>
        <div class="back curr" @click="back">
          <img src="@/assets/images/case-image/back.png" alt="">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  nextTick,
  onMounted, reactive, toRefs, watch,
} from 'vue';
import { useRouter } from 'vue-router';
import Swiper from 'swiper';
import 'swiper/css/swiper.css';
// import img from '@/assets/images/case-image/page.png';
// import img2 from '@/assets/images/home-image/carsol.png';
// import img3 from '@/assets/images/coldStorage-image/lengku.png';
import usePageSize from '@/hooks/use-page-size';
import apis from '@/request/apis';

export default {
  props: ['id'],
  setup(props) {
    const router = useRouter();
    const pageWidth = usePageSize();

    const data = reactive({
      mySwiper: null,
      imgs: [
        // { pic: img },
        // { pic: img2 },
        // { pic: img3 },
      ],
      detailData: {},
    });
    let mySwiper = null;
    const options = {
      pagination: {
        el: '.swiper-pagination',
        clickable: true,
      },
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
      autoplay: true, // 是否自动播放 true 是  false 否
      slidesPerView: 2, // 一排展示几个
      effect: 'coverflow', // 轮播图的切换效果  coverflow 3D
      centeredSlides: true, // 设定为true时，active slide会居中，而不是默认状态下的居左。
      spaceBetween: '-50%', // 每个轮播图之间设置距离（单位px）。
      loop: true, // 是否循环播放
      coverflowEffect: { // cover flow是类似于苹果将多首歌曲的封面以3D界面的形式显示出来的方式。coverflow效果参数，可选值：
        rotate: 0, // slide做3d旋转时Y轴的旋转角度
        stretch: 50, // 每个slide之间的拉伸值，越大slide靠得越紧。5.3.6 后可使用%百分比
        depth: 149, // slide的位置深度。值越大z轴距离越远，看起来越小。
        modifier: 4, // depth和rotate和stretch的倍率，相当于depth*modifier、rotate*modifier、stretch*modifier，值越大这三个参数的效果越明显
        slideShadows: false, // 是否开启slide阴影
      },
    };
    onMounted(() => {
      document.querySelector('#app').scrollTop = 0;
      const width = document.body.clientWidth;
      if (width <= 1700 && width > 1200) {
        options.coverflowEffect.depth = 110;
      } else if (width <= 1200 && width > 992) {
        options.coverflowEffect.stretch = 70;
        options.coverflowEffect.depth = 180;
        options.coverflowEffect.modifier = 2.3;
      } else if (width <= 992) {
        options.coverflowEffect.stretch = 70;
        options.coverflowEffect.depth = 180;
        options.coverflowEffect.modifier = 2.1;
      }
      mySwiper = new Swiper('.swiper-container', options);
    });

    watch(() => pageWidth, (newVal) => {
      const width = newVal.pageWidth;
      mySwiper.destroy(true, true);
      options.coverflowEffect.stretch = 50;
      options.coverflowEffect.depth = 149;
      options.coverflowEffect.modifier = 4;
      if (width <= 1700 && width > 1200) {
        options.coverflowEffect.depth = 110;
      } else if (width <= 1200 && width > 992) {
        options.coverflowEffect.stretch = 70;
        options.coverflowEffect.depth = 180;
        options.coverflowEffect.modifier = 2.3;
      } else if (width <= 992) {
        options.coverflowEffect.stretch = 70;
        options.coverflowEffect.depth = 180;
        options.coverflowEffect.modifier = 2.1;
      } else {
        options.coverflowEffect.depth = 149;
      }
      mySwiper = new Swiper('.swiper-container', options);
    }, { deep: true });

    const regex = new RegExp('<img', 'gi');
    // const regex2 = new RegExp('<p', 'gi');
    async function loadDetail() {
      const res = await apis.receptionClassicCases.getDetail({ id: props.id });
      res.briefText = res.briefText.replace(regex, '<img style="max-width: 100%; height: auto;display: block;margin: 0 auto;"');
      // res.briefText = res.briefText.replace(regex2, '<p style="display: flex; flex-flow: row wrap;"');
      data.detailData = res;
      data.imgs = res.linkedPic?.split?.(',') ?? [];
      nextTick(() => {
        mySwiper.destroy(true, true);
        mySwiper = new Swiper('.swiper-container', options);
      });
    }

    loadDetail();

    const back = () => {
      router.push({
        name: 'receptionClassicCases',
      });
    };

    return {
      ...toRefs(data),
      loadDetail,
      back,
    };
  },
};
</script>

<style scoped lang="scss">
.reception-classic-cases-detail {
  .one-img {
    margin: 0 auto;
    margin-top: 52px;
    margin-bottom: 66px;
    padding: 0px 60px;
    height: 700px;
    width: 1185px;
    overflow: hidden;
    border-radius: 40px;
    object-fit: cover;
    img {
      display: block;
      width: 100%;
      height: 100%;
      border-radius: 40px;
      object-fit: cover;
    }
  }
  .swiper {
    margin-top: 52px;
    margin-bottom: 66px;
    padding: 0px 180px;
    height: 550px;
    .swiper-slide {
      border-radius: 40px;
      width: 1185px;
      overflow: hidden;
    }
    img {
      width: 100%;
      height: 100%;
      display: block;
      border-radius: 40px;
      object-fit: cover;
    }
    .swiper-container {
      width: 100%;
      height: 100%;
    }
  }
  .content-box {
    padding: 0 200px;
    .title-box {
      display: flex;
      .title {
        height: 50px;
        line-height: 50px;
        font-size: 50px;
        font-weight: 500;
        color: #262626;
      }
      .date {
        height: 50px;
        font-size: 22px;
        font-weight: 300;
        color: #000000;
        line-height: 82px;
        margin-left: 20px;
      }
    }
    .content {
      margin-top: 41px;
      text-indent: 2em;
      font-weight: normal;
      color: #000000;
      line-height: 34px;
      overflow: hidden;
    }
    .back {
      width: 108px;
      height: 46px;
      float: right;
      margin-top: 40px;
      margin-bottom: 86px;
      img {
        display: block;
        width: 100%;
        height: 100%;
        // object-fit: cover;
      }
    }
  }
}

/* 当页面宽度大于1200px小于1700ppx */
@media screen and (min-width: 1200px) and (max-width: 1700px) {
  .reception-classic-cases-detail {
    .one-img {
      margin: 0 auto;
      margin-top: 52px;
      margin-bottom: 66px;
      padding: 0px 60px;
      height: 462px;
      width: 810px;
      overflow: hidden;
      border-radius: 40px;
      img {
        display: block;
        width: 100%;
        height: 100%;
        border-radius: 40px;
        object-fit: cover;
      }
    }
    .swiper {
      margin-top: 52px;
      margin-bottom: 66px;
      padding: 0px 180px;
      height: 380px;
      .swiper-slide {
        border-radius: 40px;
        overflow: hidden;
        width: 810px;
      }
      img {
        width: 100%;
        height: 100%;
        display: block;
        border-radius: 40px;
        object-fit: cover;
      }
      .swiper-container {
        width: 100%;
        height: 100%;
      }
    }
    .content-box {
      padding: 0 150px;
      .title-box {
        display: flex;
        .title {
          height: 50px;
          line-height: 50px;
          font-size: 50px;
          font-weight: 500;
          color: #262626;
        }
        .date {
          height: 50px;
          font-size: 22px;
          font-weight: 300;
          color: #000000;
          line-height: 82px;
          margin-left: 20px;
        }
      }
      .content {
        margin-top: 41px;
        text-indent: 2em;
        font-weight: normal;
        color: #000000;
        line-height: 34px;
      }
      .back {
        width: 108px;
        height: 46px;
        float: right;
        margin-top: 40px;
        margin-bottom: 86px;
        img {
          display: block;
          width: 100%;
          height: 100%;
          // object-fit: cover;
        }
      }
    }
  }
}
/* 当页面宽度大于992px小于1200ppx */
@media screen and (min-width: 992px) and (max-width: 1200px) {
  .reception-classic-cases-detail {
    .one-img {
      margin: 0 auto;
      margin-top: 52px;
      margin-bottom: 66px;
      padding: 0px 60px;
      height: 384px;
      width: 654px;
      overflow: hidden;
      border-radius: 40px;
      img {
        display: block;
        width: 100%;
        height: 100%;
        border-radius: 40px;
        object-fit: cover;
      }
    }
    .swiper {
      margin-top: 52px;
      margin-bottom: 66px;
      padding: 0px 120px;
      height: 300px;
      .swiper-slide {
        width: 654px;
        border-radius: 40px;
        overflow: hidden;
      }
      img {
        width: 100%;
        height: 100%;
        display: block;
        border-radius: 40px;
        object-fit: cover;
      }
      .swiper-container {
        width: 100%;
        height: 100%;
      }
    }
    .content-box {
      padding: 0 100px;
      .title-box {
        display: flex;
        .title {
          height: 44px;
          line-height: 44px;
          font-size: 44px;
          color: #262626;
        }
        .date {
          height: 44px;
          font-size: 22px;
          font-weight: 300;
          color: #000000;
          line-height: 72px;
          margin-left: 20px;
        }
      }
      .content {
        margin-top: 30px;
        text-indent: 2em;
        font-weight: normal;
        color: #000000;
        line-height: 30px;
      }
      .back {
        width: 88px;
        height: 36px;
        float: right;
        margin-top: 30px;
        margin-bottom: 66px;
      }
    }
  }
}
/* 当页面宽度大于768px小于992ppx */
@media screen and (min-width: 768px) and (max-width: 992px) {
  .reception-classic-cases-detail {
    .one-img {
      margin: 0 auto;
      margin-top: 52px;
      margin-bottom: 66px;
      padding: 0px 16px;
      width: 530px;
      height: 285px;
      overflow: hidden;
      border-radius: 20px;
      img {
        display: block;
        width: 100%;
        height: 100%;
        border-radius: 20px;
        object-fit: cover;
      }
    }
    .swiper {
      margin-top: 52px;
      margin-bottom: 66px;
      padding: 0px 60px;
      height: 220px;
      .swiper-slide {
        border-radius: 20px;
        width: 530px;
        overflow: hidden;
      }
      img {
        width: 100%;
        height: 100%;
        display: block;
        border-radius: 20px;
      }
      .swiper-container {
        width: 100%;
        height: 100%;
      }
    }
    .content-box {
      padding: 0 60px;
      .title-box {
        display: flex;
        .title {
          height: 36px;
          line-height: 36px;
          font-size: 36px;
        }
        .date {
          height: 36px;
          font-size: 22px;
          font-weight: 300;
          line-height: 54px;
          margin-left: 10px;
        }
      }
      .content {
        margin-top: 30px;
        text-indent: 2em;
        font-weight: normal;
        color: #000000;
        line-height: 30px;
      }
      .back {
        width: 88px;
        height: 36px;
        float: right;
        margin-top: 30px;
        margin-bottom: 66px;
      }
    }
  }
}
/* 当页面宽度小于768px */
@media screen and (max-width: 768px) {
  .reception-classic-cases-detail {
    .one-img {
      margin: 0 auto;
      margin-top: 22px;
      margin-bottom: 46px;
      padding: 0px 16px;
      width: 100vw;
      height: auto;
      overflow: hidden;
      border-radius: 20px;
      img {
        display: block;
        width: 100%;
        height: 100%;
        border-radius: 20px;
      }
    }
    .swiper {
      margin-top: 32px;
      margin-bottom: 36px;
      padding: 0px 10px;
      height: calc((100vw - 120px) * 700 / 1285);
      .swiper-slide {
        border-radius: 6px;
        width: calc(100vw * 0.9);
        overflow: hidden;
      }
      img {
        width: 100%;
        height: 100%;
        display: block;
        border-radius: 6px;
        object-fit: cover;
      }
      .swiper-container {
        width: 100%;
        height: 100%;
      }
    }
    .content-box {
      padding: 0 15px;
      .title-box {
        display: flex;
        .title {
          height: 20px;
          line-height: 20px;
          font-size: 20px;
        }
        .date {
          height: 20px;
          font-size: 14px;
          font-weight: 300;
          line-height: 28px;
          margin-left: 10px;
        }
      }
      .content {
        margin-top: 15px;
        text-indent: 2em;
        font-weight: normal;
        color: #000000;
        line-height: 24px;
        font-size: 14px;
      }
      .back {
        float: right;
        width: 48px;
        height: 24px;
        margin-top: 18px;
        margin-bottom: 22px;
      }
    }
  }
}

</style>
